/*********************************************************************\
*                                                                     *
* epolys.js                                          by Mike Williams *
* updated to API v3                                  by Larry Ross    *
*                                                                     *
* A Google Maps API Extension                                         *
*                                                                     *
* Adds various Methods to google.maps.Polygon and google.maps.Polyline *
*                                                                     *
* .Contains(latlng) returns true is the poly contains the specified   *
*                   GLatLng                                           *
*                                                                     *
* .Area()           returns the approximate area of a poly that is    *
*                   not self-intersecting                             *
*                                                                     *
* .Distance()       returns the length of the poly path               *
*                                                                     *
* .Bounds()         returns a GLatLngBounds that bounds the poly      *
*                                                                     *
* .GetPointAtDistance() returns a GLatLng at the specified distance   *
*                   along the path.                                   *
*                   The distance is specified in metres               *
*                   Reurns null if the path is shorter than that      *
*                                                                     *
* .GetPointsAtDistance() returns an array of GLatLngs at the          *
*                   specified interval along the path.                *
*                   The distance is specified in metres               *
*                                                                     *
* .GetIndexAtDistance() returns the vertex number at the specified    *
*                   distance along the path.                          *
*                   The distance is specified in metres               *
*                   Returns null if the path is shorter than that      *
*                                                                     *
* .Bearing(v1?,v2?) returns the bearing between two vertices          *
*                   if v1 is null, returns bearing from first to last *
*                   if v2 is null, returns bearing from v1 to next    *
*                                                                     *
*                                                                     *
***********************************************************************
*                                                                     *
*   This Javascript is provided by Mike Williams                      *
*   Blackpool Community Church Javascript Team                        *
*   http://www.blackpoolchurch.org/                                   *
*   http://econym.org.uk/gmap/                                        *
*                                                                     *
*   This work is licenced under a Creative Commons Licence            *
*   http://creativecommons.org/licenses/by/2.0/uk/                    *
*                                                                     *
***********************************************************************
*                                                                     *
* Version 1.1       6-Jun-2007                                        *
* Version 1.2       1-Jul-2007 - fix: Bounds was omitting vertex zero *
*                                add: Bearing                         *
* Version 1.3       28-Nov-2008  add: GetPointsAtDistance()           *
* Version 1.4       12-Jan-2009  fix: GetPointsAtDistance()           *
* Version 3.0       11-Aug-2010  update to v3                         *
*                                                                     *
\*********************************************************************/

export default function createEpoly() {

    // === first support methods that don't (yet) exist in v3
    window.google.maps.LatLng.prototype.distanceFrom = function (newLatLng) {
        var EarthRadiusMeters = 6378137.0; // meters
        var lat1 = this.lat();
        var lon1 = this.lng();
        var lat2 = newLatLng.lat();
        var lon2 = newLatLng.lng();
        var dLat = (lat2 - lat1) * Math.PI / 180;
        var dLon = (lon2 - lon1) * Math.PI / 180;
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = EarthRadiusMeters * c;
        return d;
    }

    window.google.maps.LatLng.prototype.latRadians = function () {
        return this.lat() * Math.PI / 180;
    }

    window.google.maps.LatLng.prototype.lngRadians = function () {
        return this.lng() * Math.PI / 180;
    }

    // === A method for testing if a point is inside a polygon
    // === Returns true if poly contains point
    // === Algorithm shamelessly stolen from http://alienryderflex.com/polygon/ 
    window.google.maps.Polygon.prototype.Contains = function (point) {
        var j = 0;
        var oddNodes = false;
        var x = point.lng();
        var y = point.lat();
        for (var i = 0; i < this.getPath().getLength(); i++) {
            j++;
            if (j === this.getPath().getLength()) { j = 0; }
            if (((this.getPath().getAt(i).lat() < y) && (this.getPath().getAt(j).lat() >= y))
                || ((this.getPath().getAt(j).lat() < y) && (this.getPath().getAt(i).lat() >= y))) {
                if (this.getPath().getAt(i).lng() + (y - this.getPath().getAt(i).lat())
                    / (this.getPath().getAt(j).lat() - this.getPath().getAt(i).lat())
                    * (this.getPath().getAt(j).lng() - this.getPath().getAt(i).lng()) < x) {
                    oddNodes = !oddNodes
                }
            }
        }
        return oddNodes;
    }

    // === A method which returns the approximate area of a non-intersecting polygon in square metres ===
    // === It doesn't fully account for spherical geometry, so will be inaccurate for large polygons ===
    // === The polygon must not intersect itself ===
    window.google.maps.Polygon.prototype.Area = function () {
        var a = 0;
        var j = 0;
        var b = this.Bounds();
        var x0 = b.getSouthWest().lng();
        var y0 = b.getSouthWest().lat();
        for (var i = 0; i < this.getPath().getLength(); i++) {
            j++;
            if (j === this.getPath().getLength()) { j = 0; }
            var x1 = this.getPath().getAt(i).distanceFrom(new window.google.maps.LatLng(this.getPath().getAt(i).lat(), x0));
            var x2 = this.getPath().getAt(j).distanceFrom(new window.google.maps.LatLng(this.getPath().getAt(j).lat(), x0));
            var y1 = this.getPath().getAt(i).distanceFrom(new window.google.maps.LatLng(y0, this.getPath().getAt(i).lng()));
            var y2 = this.getPath().getAt(j).distanceFrom(new window.google.maps.LatLng(y0, this.getPath().getAt(j).lng()));
            a += x1 * y2 - x2 * y1;
        }
        return Math.abs(a * 0.5);
    }

    // === A method which returns the length of a path in metres ===
    window.google.maps.Polygon.prototype.Distance = function () {
        var dist = 0;
        for (var i = 1; i < this.getPath().getLength(); i++) {
            dist += this.getPath().getAt(i).distanceFrom(this.getPath().getAt(i - 1));
        }
        return dist;
    }

    // === A method which returns the bounds as a GLatLngBounds ===
    window.google.maps.Polygon.prototype.Bounds = function () {
        var bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < this.getPath().getLength(); i++) {
            bounds.extend(this.getPath().getAt(i));
        }
        return bounds;
    }

    // === A method which returns a GLatLng of a point a given distance along the path ===
    // === Returns null if the path is shorter than the specified distance ===
    window.google.maps.Polygon.prototype.GetPointAtDistance = function (metres, total) {
        // some awkward special cases
        if (metres === 0) return this.getPath().getAt(0);
        if (metres < 0) return null;
        if (total === metres) {
            let dist = this.getPath().getLength() - parseInt(Math.random() * 5)
            console.log(dist)
            return (this.getPath().getAt(dist - 1));
        }
        // console.log(this.getPath().getLength())
        if (this.getPath().getLength() < 2) return null;
        var dist = 0;
        var olddist = 0;
        for (var i = 1; (i < this.getPath().getLength() && dist < metres); i++) {
            olddist = dist;
            dist += this.getPath().getAt(i).distanceFrom(this.getPath().getAt(i - 1));
        }
        if (dist < metres) {
            return null;
        }
        var p1 = this.getPath().getAt(i - 2);
        var p2 = this.getPath().getAt(i - 1);
        var m = (metres - olddist) / (dist - olddist);
        return new window.google.maps.LatLng(p1.lat() + (p2.lat() - p1.lat()) * m, p1.lng() + (p2.lng() - p1.lng()) * m);
    }

    // === A method which returns an array of GLatLngs of points a given interval along the path ===
    window.google.maps.Polygon.prototype.GetPointsAtDistance = function (metres) {
        var next = metres;
        var points = [];
        // some awkward special cases
        if (metres <= 0) return points;
        var dist = 0;
        var olddist = 0;
        for (var i = 1; (i < this.getPath().getLength()); i++) {
            olddist = dist;
            dist += this.getPath().getAt(i).distanceFrom(this.getPath().getAt(i - 1));
            while (dist > next) {
                var p1 = this.getPath().getAt(i - 1);
                var p2 = this.getPath().getAt(i);
                var m = (next - olddist) / (dist - olddist);
                points.push(new window.google.maps.LatLng(p1.lat() + (p2.lat() - p1.lat()) * m, p1.lng() + (p2.lng() - p1.lng()) * m));
                next += metres;
            }
        }
        return points;
    }





    // === Copy all the above functions to GPolyline ===
    window.google.maps.Polyline.prototype.Contains = window.google.maps.Polygon.prototype.Contains;
    window.google.maps.Polyline.prototype.Area = window.google.maps.Polygon.prototype.Area;
    window.google.maps.Polyline.prototype.Distance = window.google.maps.Polygon.prototype.Distance;
    window.google.maps.Polyline.prototype.Bounds = window.google.maps.Polygon.prototype.Bounds;
    window.google.maps.Polyline.prototype.GetPointAtDistance = window.google.maps.Polygon.prototype.GetPointAtDistance;
    window.google.maps.Polyline.prototype.GetPointsAtDistance = window.google.maps.Polygon.prototype.GetPointsAtDistance;
    window.google.maps.Polyline.prototype.GetIndexAtDistance = window.google.maps.Polygon.prototype.GetIndexAtDistance;
    window.google.maps.Polyline.prototype.Bearing = window.google.maps.Polygon.prototype.Bearing;

}



